import { paths } from "helpers/paths";
import { useState, useEffect, useCallback } from "react";
import NavPanel from "../NavPanel/NavPanel";
import NavListItem from "./NavListItem";
import {
  useFloating,
  useInteractions,
  useHover,
  safePolygon,
  flip,
  shift,
  offset,
  autoUpdate,
} from "@floating-ui/react";
import { NavPanelWrapper } from "../NavPanel/NavPanelWrapper";

const NavList = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { x, y, strategy, refs, context } = useFloating({
    whileElementsMounted: autoUpdate,
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: "bottom-start",
    middleware: [flip(), shift(), offset(20)],
  });

  const hover = useHover(context, { handleClose: safePolygon() });
  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

  const handleResize = useCallback(() => {
    if (window.innerWidth > 768) {
      setIsOpen(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  const onClose = () => setIsOpen(false);

  return (
    <ul className="navList">
      <li>
        <NavListItem
          title="Features"
          to={paths.features}
          ref={refs.setReference}
          popperProps={getReferenceProps()}
        />
        {isOpen && (
          <NavPanelWrapper
            ref={refs.setFloating}
            position={strategy}
            top={y}
            left={x}
            {...getFloatingProps()}
          >
            <NavPanel onClose={onClose} />
          </NavPanelWrapper>
        )}
      </li>
      <li>
        <NavListItem to={paths.pricing} title="Pricing" />
      </li>
      <li>
        <NavListItem to={paths.integrations} title="Partners" />
      </li>
      {/* <li className="submenu-container">
        Tools{" "}
        <ul className="submenu">
          <li>
            <a
              href="/inventory-template"
              target="_blank"
              rel="noopener noreferrer"
            >
              Inventory Template
            </a>
          </li>
          <li>
            <a
              href="/shipping-label-template"
              target="_blank"
              rel="noopener noreferrer"
            >
              Create Shipping Label
            </a>
          </li>
        </ul>
      </li> */}
      <li>
        <NavListItem to={paths.ai} title="AI" />
      </li>
      <li>
        <NavListItem to={paths.inventory} title="Inventory" />
      </li>
    </ul>
  );
};

export default NavList;
