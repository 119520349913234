import React from "react";
import { useIntercom } from "react-use-intercom";
import { MouseEventHandler } from "react";
import { paths } from "helpers/paths";

const AddressList: React.FC = (props) => {
  const { showNewMessages } = useIntercom();
  const talkOnClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    showNewMessages("");
  };

  return (
    <ul className="addressList">
      <li>
        <a href="tel:+18002432986" className="color">
          (800) 243-2986
        </a>
      </li>
      <a href={paths.typeform} className="color">
        Contact Sales
      </a>
      <li>
        <ul className="skulabsAddress">
          <li>12450 NW 39th St Coral Springs, FL, 33065</li>
        </ul>
      </li>
    </ul>
  );
};

export default AddressList;
