export const paths = {
  // Main routes
  root: "/",
  about: "/about",
  partners: "/integrations",
  integrations: "/integrations",
  useCases: "/use-cases",
  pricing: "/pricing",
  features: "/features",
  hardware: "/hardware",
  inventory: "/inventory",
  inventoryPricing: "/inventory#pricing",
  hardwareMobileDevices: "/hardware/mobile-devices",
  hardwareBarcodeScanners: "/hardware/barcode-scanners",
  hardwareLabelPrinters: "/hardware/label-printers",
  hardwareScales: "/hardware/scales",
  hardwareNetworkingGear: "/hardware/networking-gear",
  sitemap: "/sitemap",
  termsOfUse: "/terms-of-use",
  privacyPolicy: "/privacy-policy",
  gdpr: "/gdpr",
  ccpa: "/ccpa",
  reviews: "/reviews",
  thanks: "/thanks",
  redirecting: "/redirecting",

  connect: "/connect",
  book: "/book",
  health411: "/health411",
  process: "/process",
  stitchlabsAlternative: "/stitchlabs-alternative",
  changelog: "/changelog",
  ai: "/ai",
  skulabsSignup: "/skulabsSignup",
  careers: "/careers",

  // Support links
  supportLinks: {
    inventoryGuide: "/support/inventory/",
    orderFulfillmentGuide: "/support/orders/",
    batchPickingGuide: "/support/batches/",
    shippingGuide: "/support/shipping/",
    purchaseOrderGuide: "/support/purchase-orders/",
    insightsReportingGuide: "/support/insights/",
    contactManagementGuide: "/support/contacts/",
    printingGuide: "/support/printing/",
    questionsAndAnswers: "/qa/",
  },

  // Individual features
  inventoryManagement: "/features/inventory-management",
  orderPicking: "/features/picking",
  shipping: "/features/shipping",
  batchPicking: "/features/batch-picking",
  orderManagement: "/features/order-management",
  pointOfSale: "/features/point-of-sale",
  purchaseOrder: "/features/purchase-orders",
  warehouseManagement: "/features/warehouse-management",
  catalogManagement: "/features/catalog-management",
  insightsReporting: "/features/insights",

  // Integrations
  shift4shop: "/integrations/shift4shop",
  bigCommerce: "/integrations/bigcommerce",
  magento: "/integrations/magento",
  skulabsShopify: "/integrations/shopify",
  shopify: "/integrations/shopify",
  shopifyPlus: "/integrations/shopifyPlus",
  woocommerce: "/integrations/woocommerce",
  amazon: "/integrations/amazon",
  ebay: "/integrations/ebay",
  etsy: "/integrations/etsy",
  walmart: "/integrations/walmart",
  square: "/integrations/square",
  shipstation: "/integrations/shipstation",
  skulabsAmazon: "/integrations/amazon",
  skulabsEtsy: "/integrations/etsy",
  duoplane: "/integrations/duoplane",
  quickbooks: "/integrations/quickbooks",

  // Inventory guides
  benefitsOfInventoryManagementSystems: "/inventory-management-software",
  inventoryManagementForSmallBusiness:
    "/inventory-management-for-small-business",
  whatIsMultichannelInventoryControl: "/multichannel-inventory-control",
  introductionToInventoryForecasting: "/inventory-forecasting",
  introductionToCycleCounts: "/cycle-counts",
  serializedInventoryManagement: "/serialized-inventory-management",
  shippingAndReceivingInventory: "/features/shipping",

  // Global skulabs routes
  skulabsSignupForm: "https://next.skulabs.com/s/api/account/signup_form",
  activateLocal: "https://app.local.test.skulabs.com/get-started#activate",
  activate: "https://app.skulabs.com/get-started#activate",
  activateNext: "https://next.skulabs.com/get-started#activate",
  activateCanary: "https://canary.skulabs.com/get-started#activate",
  signup: "https://skulabs.com/pricing",
  login: "https://login.skulabs.com/go/SKULabs",
  inventorySupport: "https://help.my.skulabs.com/en/",
  inventoryLogin: "https://login.skulabs.com/go/Inventory",
  typeform:
    "https://0ksofc80okw.typeform.com/to/QFRN4RH6?typeform-source=www.skulabs.com",
  blog: "https://blog.skulabs.com/",
  instagram: "https://www.instagram.com/skulabs/",
  support: "https://help.skulabs.com/",
  api: "https://skulabs.mintlify.app/",
  legacyLogin: "https://app.skulabs.com/login", // Unused as of September 2023
  talk: "https://0ksofc80okw.typeform.com/to/QFRN4RH6?typeform-source=www.skulabs.com", // Retired talk January 2024
  qa: "https://help.skulabs.com/en/collections/2163716-faq",
  status: "https://skulabs.statuspage.io/",
  calendly: "https://calendly.com/skulabs/",
  reactwww: "https://react-www.skulabs.com",
};

export default paths;
