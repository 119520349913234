import React, {
  createContext,
  useEffect,
  useState,
  lazy,
  Suspense,
  useMemo,
} from "react";
import {
  Navigate,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import ReactGA from "react-ga4";
import paths from "helpers/paths";
import isChatOnline from "helpers/dateUtils";
import getTitleForPath from "helpers/titleUtils";
import SkulabsDotCom from "SkulabsDotCom";
import { ErrorBoundary, getGlobalObject } from "helpers/DataDog";
import integrationsList from "assets/data/integrationData";
import {
  legacyRedirectsData,
  externalRedirectsData,
  DynamicRedirect,
  ExternalRedirect,
  handleSpecialRedirects,
  handleRedirects,
} from "helpers/redirectsConfig";

const Demo = lazy(() => import("components/Content/Demo/Demo"));
const ShippingTemplate = lazy(
  () => import("components/Content/ShippingTemplate")
);
const InventoryTemplate = lazy(
  () => import("components/Content/InventoryTemplate")
);
const BatchPicking = lazy(
  () => import("components/Content/Features/BatchPicking")
);
const CatalogManagement = lazy(
  () => import("components/Content/Features/CatalogManagement")
);
const Features = lazy(() => import("components/Content/Features/Features"));
const InsightsReporting = lazy(
  () => import("components/Content/Features/InsightsReporting")
);
const Integrations = lazy(() => import("pages/Integrations"));
const InventoryManagement = lazy(
  () => import("components/Content/Features/InventoryManagement")
);
const OrderManagement = lazy(
  () => import("components/Content/Features/OrderManagement")
);
const OrderPicking = lazy(
  () => import("components/Content/Features/OrderPicking")
);
const PointOfSale = lazy(
  () => import("components/Content/Features/PointOfSale")
);
const PurchaseOrders = lazy(
  () => import("components/Content/Features/PurchaseOrders")
);
const Shipping = lazy(() => import("components/Content/Features/Shipping"));
const WarehouseManagement = lazy(
  () => import("components/Content/Features/WarehouseManagement")
);
const Academy = lazy(() => import("components/Content/Pages/Academy/Academy"));
const AcademyBenefits = lazy(
  () => import("components/Content/Pages/Academy/AcademyBenefits")
);
const AcademyCycleCounts = lazy(
  () => import("components/Content/Pages/Academy/AcademyCycleCounts")
);
const AcademyInventoryForecasting = lazy(
  () => import("components/Content/Pages/Academy/AcademyInventoryForecasting")
);
const AcademyInventoryManagement = lazy(
  () => import("components/Content/Pages/Academy/AcademyInventoryManagement")
);
const AcademyMultichannelInventory = lazy(
  () => import("components/Content/Pages/Academy/AcademyMultichannelInventory")
);
const AcademySerializedInventory = lazy(
  () => import("components/Content/Pages/Academy/AcademySerializedInventory")
);
const Ai = lazy(() => import("components/Content/Pages/Ai/Ai"));
const Book = lazy(() => import("components/Content/Pages/Book"));
const Ccpa = lazy(() => import("components/Content/Pages/Ccpa"));
const ChangeLog = lazy(() => import("components/Content/Pages/ChangeLog"));
const Connect = lazy(() => import("components/Content/Pages/Connect"));
const Gdpr = lazy(() => import("components/Content/Pages/Gdpr"));
const Hardware = lazy(() => import("components/Content/Pages/Hardware"));
const Health411 = lazy(() => import("components/Content/Pages/Health411"));
const Loading = lazy(() => import("components/Content/Pages/Loading"));
const InventoryLanding = lazy(
  () => import("components/Content/Pages/Inventory/InventoryLanding")
);
const NotFound = lazy(() => import("components/Content/Pages/NotFound"));
const PrivacyPolicy = lazy(
  () => import("components/Content/Pages/PrivacyPolicy")
);
const Process = lazy(() => import("components/Content/Pages/Process"));
const Redirecting = lazy(() => import("components/Content/Pages/Redirecting"));
const Reviews = lazy(() => import("components/Content/Pages/Reviews"));
const Sitemap = lazy(() => import("components/Content/Pages/Sitemap"));
const StitchlabAlternative = lazy(
  () => import("components/Content/Pages/StitchlabAlternative")
);
const TermsOfUse = lazy(() => import("components/Content/Pages/TermsOfUse"));
const About = lazy(() => import("components/Sections/About/About"));
const Landing = lazy(() => import("components/Sections/Landing/Landing"));
const UseCases = lazy(() => import("components/Sections/UseCases/UseCases"));
const Pricing = lazy(() => import("components/Sections/Pricing/Pricing"));
const Signup = lazy(() => import("components/Elements/Signup/Signup"));
const NewIntegration = lazy(() => import("pages/SingleIntegration"));
const LoadingFallback = () => <div>Loading...</div>;

interface ISkulabsContextProps {
  showFooterCta: boolean;
  updateShowFooterCta: React.Dispatch<React.SetStateAction<boolean>>;
  chatOnline: boolean;
  updateChatOnline: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  updateTitle: React.Dispatch<React.SetStateAction<string>>;
}

export const SkulabsContext = createContext<ISkulabsContextProps>(null!);

const SkulabsRouter = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location.pathname;

  const [showFooterCta, updateShowFooterCta] = useState<boolean>(true);
  const [chatOnline, updateChatOnline] = useState<boolean>(true);
  const [title, updateTitle] = useState<string>(
    "SKULabs - Inventory management software and WMS for ecommerce retailers"
  );

  useEffect(() => {
    const checkChatOnline = () => updateChatOnline(isChatOnline());
    checkChatOnline();
    const intervalId = setInterval(checkChatOnline, 600000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: pathname });
    updateTitle(getTitleForPath(pathname));
    document.documentElement.scrollTo(0, 0);

    const globalObj = getGlobalObject<Window>();
    if (!globalObj.DD_RUM) {
      console.warn(
        "@datadog/rum-react-integration: Datadog RUM SDK is not initialized."
      );
      return;
    }
    if (!globalObj.DD_RUM?.startView) {
      console.warn(
        "@datadog/rum-react-integration: Manual tracking not supported. Try updating the Datadog RUM SDK."
      );
      return;
    }
    if (!globalObj.DD_RUM?.getInitConfiguration()?.trackViewsManually) {
      console.warn(
        "@datadog/rum-react-integration: The trackViewsManually flag in RUM initialization must be set to %ctrue%c.",
        "color:green",
        "color:default"
      );
      return;
    }

    globalObj.DD_RUM.startView(pathname);
  }, [pathname]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  const starterContext = useMemo(
    () => ({
      showFooterCta,
      updateShowFooterCta,
      chatOnline,
      updateChatOnline,
      title,
      updateTitle,
    }),
    [showFooterCta, chatOnline, title]
  );

  const specialRedirect = handleSpecialRedirects(pathname);
  if (specialRedirect) {
    return specialRedirect;
  }

  handleRedirects(pathname, navigate);

  return (
    <SkulabsContext.Provider value={starterContext}>
      <ErrorBoundary
        fallback={(error) => (
          <div>An error was encountered {error.message}</div>
        )}
      >
        <Suspense fallback={<LoadingFallback />}>
          <Routes>
            <Route path="/demo" element={<Demo />} />
            <Route
              path="/shipping-label-template"
              element={<ShippingTemplate />}
            />
            <Route path="/inventory-template" element={<InventoryTemplate />} />
          </Routes>

          {![
            "/demo",
            "/shipping-label-template",
            "/inventory-template",
          ].includes(location.pathname) && (
            <SkulabsDotCom>
              <ErrorBoundary
                fallback={(error) => (
                  <div>An error was encountered {error.message}</div>
                )}
              >
                <Suspense fallback={<LoadingFallback />}>
                  <Routes>
                    {legacyRedirectsData.map(([oldPath, newPath], index) => (
                      <Route
                        key={index}
                        path={oldPath}
                        element={<Navigate to={newPath} replace />}
                      />
                    ))}
                    {externalRedirectsData.map(
                      ([oldPath, externalUrl], index) => (
                        <Route
                          key={index}
                          path={oldPath}
                          element={<ExternalRedirect to={externalUrl} />}
                        />
                      )
                    )}
                    <Route
                      path="/partners/:slug"
                      element={<DynamicRedirect />}
                    />
                    <Route
                      path={"/partners"}
                      element={<Navigate to="/integrations" />}
                    />
                    <Route path="*" element={<NotFound />} />
                    <Route path={paths.root} element={<Landing />} />
                    <Route path={paths.about} element={<About />} />
                    <Route path={paths.skulabsSignup} element={<Signup />} />
                    <Route path={paths.useCases} element={<UseCases />} />
                    <Route path={paths.pricing} element={<Pricing />} />
                    <Route
                      path={paths.integrations}
                      element={<Integrations />}
                    />
                    <Route path={paths.features} element={<Features />} />
                    <Route
                      path={paths.orderPicking}
                      element={<OrderPicking />}
                    />
                    <Route path={paths.shipping} element={<Shipping />} />
                    <Route
                      path={paths.batchPicking}
                      element={<BatchPicking />}
                    />
                    <Route
                      path={paths.orderManagement}
                      element={<OrderManagement />}
                    />
                    <Route path={paths.pointOfSale} element={<PointOfSale />} />
                    <Route
                      path={paths.inventoryManagement}
                      element={<InventoryManagement />}
                    />
                    <Route
                      path={paths.purchaseOrder}
                      element={<PurchaseOrders />}
                    />
                    <Route
                      path={paths.warehouseManagement}
                      element={<WarehouseManagement />}
                    />
                    <Route
                      path={paths.catalogManagement}
                      element={<CatalogManagement />}
                    />
                    <Route
                      path={paths.insightsReporting}
                      element={<InsightsReporting />}
                    />
                    <Route path={paths.termsOfUse} element={<TermsOfUse />} />
                    <Route path={paths.sitemap} element={<Sitemap />} />
                    <Route
                      path={paths.privacyPolicy}
                      element={<PrivacyPolicy />}
                    />
                    <Route path={paths.gdpr} element={<Gdpr />} />
                    <Route path={paths.ccpa} element={<Ccpa />} />
                    <Route path={paths.reviews} element={<Reviews />} />
                    <Route path={paths.thanks} element={<Reviews />} />
                    <Route path={paths.connect} element={<Connect />} />
                    <Route path={paths.book} element={<Book />} />
                    <Route path={paths.health411} element={<Health411 />} />
                    <Route path={paths.process} element={<Process />} />
                    <Route
                      path={paths.stitchlabsAlternative}
                      element={<StitchlabAlternative />}
                    />
                    <Route path={paths.changelog} element={<ChangeLog />} />
                    <Route path={paths.ai} element={<Ai />} />
                    <Route path={paths.hardware} element={<Hardware />} />
                    <Route
                      path={paths.inventory}
                      element={<InventoryLanding />}
                    />
                    <Route
                      path={paths.hardwareBarcodeScanners}
                      element={<Hardware status="barcodeScanners" />}
                    />
                    <Route
                      path={paths.hardwareLabelPrinters}
                      element={<Hardware status="labelPrinters" />}
                    />
                    <Route
                      path={paths.hardwareMobileDevices}
                      element={<Hardware status="mobileDevices" />}
                    />
                    <Route
                      path={paths.hardwareScales}
                      element={<Hardware status="scales" />}
                    />
                    <Route
                      path={paths.hardwareNetworkingGear}
                      element={<Hardware status="networkingGear" />}
                    />
                    <Route
                      path="/insights"
                      element={<Navigate to={paths.insightsReporting} />}
                    />
                    <Route
                      path="/integrate/*"
                      element={<Navigate to={paths.integrations} />}
                    />
                    <Route
                      path="/mobile"
                      element={<Navigate to={paths.features} />}
                    />
                    <Route
                      path="/pack"
                      element={<Navigate to={paths.shipping} />}
                    />
                    <Route
                      path="/pick"
                      element={<Navigate to={paths.orderPicking} />}
                    />
                    <Route
                      path="/ship"
                      element={<Navigate to={paths.shipping} />}
                    />
                    <Route
                      path={paths.benefitsOfInventoryManagementSystems}
                      element={<Academy content={<AcademyBenefits />} />}
                    />
                    <Route
                      path={paths.inventoryManagementForSmallBusiness}
                      element={
                        <Academy content={<AcademyInventoryManagement />} />
                      }
                    />
                    <Route
                      path={paths.whatIsMultichannelInventoryControl}
                      element={
                        <Academy content={<AcademyMultichannelInventory />} />
                      }
                    />
                    <Route
                      path={paths.introductionToInventoryForecasting}
                      element={
                        <Academy content={<AcademyInventoryForecasting />} />
                      }
                    />
                    <Route
                      path={paths.introductionToCycleCounts}
                      element={<Academy content={<AcademyCycleCounts />} />}
                    />
                    <Route
                      path={paths.serializedInventoryManagement}
                      element={
                        <Academy content={<AcademySerializedInventory />} />
                      }
                    />
                    {integrationsList.map((company) => {
                      const link = `/integrations/${company.companyName
                        .toLowerCase()
                        .replace(" ", "-")}`;
                      return (
                        <Route
                          key={company.companyName}
                          path={link}
                          element={<NewIntegration data={company} />}
                        />
                      );
                    })}
                    <Route path={"/loading"} element={<Loading />} />
                    <Route path="/redirecting" element={<Redirecting />} />
                  </Routes>
                </Suspense>
              </ErrorBoundary>
            </SkulabsDotCom>
          )}
        </Suspense>
      </ErrorBoundary>
    </SkulabsContext.Provider>
  );
};

export default SkulabsRouter;
