import FooterCta from 'components/Elements/FooterCta/FooterCta';
import MainNav from 'components/Navigation/MainNav/MainNav';
import { SkulabsContext } from 'components/Routing/SkulabsRouter';
import Footer from 'components/Sections/Footer/Footer';
import { ReactElement, useContext } from 'react'
import './styles/index.scss'
import { useLocation } from 'react-router-dom';
import { InventoryNav } from 'components/Content/Pages/Inventory/InventoryNav';
import { SkulabsContainer } from 'components/Elements/SkulabsContainer/SkulabsContainer';

interface ISkulabsDotCom {
  children: ReactElement | ReactElement[]
}

const SkulabsDotCom = (props: ISkulabsDotCom) => {
  // Temp solution for enabling dark mode on the /ai path
  // Grab the path then apply .darkMode to the main container, then override all
  // the styles through darkMode.scss
  // Since we don't have access to the <body> through react, we change the background
  // from Ai.tsx via useEffect and updating the node directly.
  const location = useLocation()

  const { showFooterCta } = useContext(SkulabsContext)

  return (
    <div className={`skulabs ${location.pathname === '/ai' ? 'darkMode' : ''}`}>
          <MainNav />
      <hr />
      {/* <Breadcrumbs /> */}
      {props.children}

      { showFooterCta &&
        <SkulabsContainer>
          <FooterCta />
        </SkulabsContainer>
      }

      <Footer />
    </div>
  )
}



export default SkulabsDotCom;
