import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { OnlineMark } from "components/Elements/OnlineMark/OnlineMark";
import MainNavMasthead from "components/Navigation/MainNav/MainNavMasthead";
import NavListItem from "./NavListItem";
import { paths } from "helpers/paths";
import { BaseSyntheticEvent } from "react";

const InventoryNavList = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [hash]);

  const onClickPricing = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    const pricingContent = document.querySelector("#pricing");
    pricingContent?.scrollIntoView({ behavior: "smooth" });
  };

  const onClickFeatures = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    const featuresContent = document.querySelector("#features");
    featuresContent?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <ul className="navList">
      <li>
        <a href="#pricing" className="navLink" onClick={onClickPricing}>
          Pricing
        </a>
      </li>
      <li>
        <a href="#features" className="navLink" onClick={onClickFeatures}>
          Features
        </a>
      </li>
      <li>
        <NavListItem to={paths.root} title="Pro" />
      </li>
    </ul>
  );
};

export default InventoryNavList;
