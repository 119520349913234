import { Strategy } from "@floating-ui/react";
import { forwardRef, ReactNode, Ref } from "react";

interface INavPanelWrapperProps {
  children: ReactNode
  position: Strategy
  top: number | null
  left: number | null
}

export const NavPanelWrapper = forwardRef((props: INavPanelWrapperProps, ref: Ref<HTMLDivElement>) => {
  return (
    <div 
      ref={ref}
      style={{
        width: '100%',
        maxWidth: '960px',
        position: props.position,
        top: props.top ?? 0,
        left: props.left ?? 0,
      }}
    >
      {props.children}
    </div>
  )
})
