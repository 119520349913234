// helpers/titleUtils.ts
import paths from "helpers/paths";

const getTitleForPath = (pathname: string): string => {
  switch (pathname) {
    case paths.about:
      return "About Us - SKULabs";
    case paths.useCases:
      return "";
    case paths.pricing:
      return "Plans & Pricing - SKULabs";
    case paths.features:
      return "SKULabs Features Overview";
    case paths.hardware:
      return "SKULabs - Recommended Mobile Devices";
    case paths.hardwareMobileDevices:
      return "SKULabs - Recommended Mobile Devices";
    case paths.hardwareBarcodeScanners:
      return "SKULabs - Recommended Barcode Scanners";
    case paths.hardwareLabelPrinters:
      return "SKULabs - Recommended Label Printers";
    case paths.hardwareScales:
      return "SKULabs - Recommended Scales";
    case paths.hardwareNetworkingGear:
      return "SKULabs - Recommended Networking Gear";
    case paths.sitemap:
      return "SKULabs - Sitemap";
    case paths.termsOfUse:
      return "SKULabs - Terms of Use";
    case paths.privacyPolicy:
      return "SKULabs - Privacy Policy";
    case paths.gdpr:
      return "SKULabs - GDPR Data Processing Addendum";
    case paths.ccpa:
      return "SKULabs - CCPA Data Processing Addendum";
    case paths.reviews:
      return "SKULabs - Customer Reviews";
    case paths.thanks:
      return "SKULabs - Let other merchants know how our app is working for you by leaving a review.";
    case paths.redirecting:
      return "SKULabs - Redirecting you...";
    case paths.book:
      return "Request a Demo with SKULabs";
    case paths.inventoryManagement:
      return "SKULabs - Inventory Management";
    case paths.orderPicking:
      return "SKULabs - Order Picking";
    case paths.shipping:
      return "SKULabs - Multi-Carrier Shipping";
    case paths.batchPicking:
      return "SKULabs - Batch Picking";
    case paths.orderManagement:
      return "SKULabs - Order Management";
    case paths.pointOfSale:
      return "SKULabs - Point of Sale";
    case paths.purchaseOrder:
      return "SKULabs - Purchase Orders";
    case paths.warehouseManagement:
      return "SKULabs - Warehouse Management";
    case paths.catalogManagement:
      return "SKULabs - Catalog Management";
    case paths.insightsReporting:
      return "SKULabs - Insights Reporting";
    case paths.benefitsOfInventoryManagementSystems:
      return "Benefits of Inventory Management Software";
    case paths.inventoryManagementForSmallBusiness:
      return "Inventory Management for Small Business";
    case paths.whatIsMultichannelInventoryControl:
      return "What is Multichannel Inventory Control?";
    case paths.introductionToInventoryForecasting:
      return "Introduction to Inventory Forecasting";
    case paths.introductionToCycleCounts:
      return "Introduction to Cycle Counts";
    case paths.serializedInventoryManagement:
      return "Serialized Inventory Management";
    case paths.shippingAndReceivingInventory:
      return "";
    case paths.root:
    default:
      return "Inventory management software and WMS for ecommerce retailers";
  }
};

export default getTitleForPath;
