import React, { useState } from "react";
import { getUtmData } from "helpers/utm";
import ReactGA from "react-ga4";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface IEmailFormProps {
  placeholder?: string;
  buttonText?: string;
  source?: string;
}

const LiveDemoForm: React.FC<IEmailFormProps> = ({
  placeholder = "Your email",
  buttonText = "Get Live Demo",
  source
}) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const location = useLocation();
  source = source || location.pathname;
  const [formSubmittedSuccessfully, setFormSubmittedSuccessfully] =
    useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const utm_data = getUtmData();
      // if localhost:3002 then use http://localhost:3006
      let domainPrefix = '';
      if (window.location.hostname === "localhost" && window.location.port === "3002") {
        domainPrefix = "http://localhost:3006";
      }
      const response = await fetch(domainPrefix + "/submitContact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, source: source || utm_data?.utm_source || 'provide_email', utm_data }),
      });
      if (response.ok) {
        toast.success(
          "Thank you! Your demo request has been successfully submitted. We will contact you shortly!",
          {
            theme: "colored",
          }
        );

        setFormSubmittedSuccessfully(true);

        ReactGA.event({
          category: "Provide Email Form",
          action: "Provide_email",
          label: buttonText,
        });

        let responseData: any;
        try {
          responseData = await response.json();
        } catch (e) {
          console.error("Error parsing response data", e);
        }

        setTimeout(() => navigate(`/demo?email=${email}&sent=${responseData?.success ? 'yes' : 'no'}`), 2000);

      } else {
        toast.error(
          "Oops, something went wrong. Please try again or contact us directly.",
          {
            theme: "colored",
          }
        );
      }
    } catch (error) {
      toast.error(
        "There was a problem sending your request. Please check your internet connection and try again.",
        {
          theme: "colored",
        }
      );
    }
  };

  return (
    <form onSubmit={handleSubmit} className="email-form">
      {formSubmittedSuccessfully && (
        <img
          src="https://ct.capterra.com/capterra_tracker.gif?vid=2104781&vkey=2c23ccf67d846288ef9d7b2b82b58c94"
          alt=""
        />
      )}
      <input
        type="email"
        placeholder={placeholder}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="email-input"
        required
      />

      <button type="submit" className="email-button">
        {buttonText}
      </button>
      <ToastContainer />
    </form>
  );
};

export default LiveDemoForm;
