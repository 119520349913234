import { INavItem } from "types/commonTypes";
import { paths } from "./paths";

// The features navigation panel content.
export const featuresNavContent: {title: string, options: INavItem[]}[] = [
  {
    title: 'Picking, packing & shipping',
    options: [
      {title: 'Order picking', subtitle: 'Pick, pack, and ship at top speed.', to: paths.orderPicking},
      {title: 'Multi-carrier shipping', subtitle: 'Get rates and print shipping labels.', to: paths.shipping},
      {title: 'Batch picking', subtitle: 'Pick and ship batches of orders together.', to: paths.batchPicking},
      {title: 'Order management', subtitle: 'All your orders in one place.', to: paths.orderManagement},
    ]
  },
  {
    title: 'Inventory & sales',
    options: [
      {title: 'Inventory management', subtitle: 'Stock changes across sales channels.', to: paths.inventoryManagement},
      {title: 'Purchase orders', subtitle: 'Simple & efficient merchandise orders.', to: paths.purchaseOrder},
      {title: 'Warehouse management', subtitle: 'Track inventory across locations.', to: paths.warehouseManagement},
      {title: 'Catalog management', subtitle: 'One home for all of your products.', to: paths.catalogManagement},
      {title: 'Point of sale', subtitle: 'Syncs with your POS', to: paths.pointOfSale},
    ]
  },
  {
    title: 'Reports & integrations',
    options: [
      {title: 'Insights Reporting ', subtitle: "A bird's-eye view of business operations", to: paths.insightsReporting},
      {title: 'Integrations', subtitle: 'Connected partners', to: paths.integrations},
    ]
  }
]
