import { SkulabsContext } from "components/Routing/SkulabsRouter";
import { useContext } from "react";
import OnlineStatusMark from "../OnlineStatusMark/OnlineStatusMark";

export interface IOnlineMark {
  status?: "online" | "offline";
}

export const OnlineMark = (props: IOnlineMark) => {
  const { chatOnline } = useContext(SkulabsContext);

  return (
    <div className="mark">
      <span>
        <span className="text">Call us at: </span>
        <a href="tel:+18002432986" className={`phone ${chatOnline}`}>
          +1.800.243.2986
        </a>
      </span>
      {/* <OnlineStatusMark /> */}
    </div>
  );
};
