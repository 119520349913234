import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  useFloating,
  useInteractions,
  flip,
  shift,
  offset,
  autoUpdate,
  useClick,
} from "@floating-ui/react";
import NavPanel from "../NavPanel/NavPanel";
import { NavPanelWrapper } from "../NavPanel/NavPanelWrapper";
import { NavControlButton } from "./NavControlButton";
import { paths } from "helpers/paths";

const NavControl = () => {
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
  };

  const [isOpen, setIsOpen] = useState(false);

  const onOpenChange = (open: boolean) => {
    // Scroll to the top when opening the mobile menu so we can align it to the
    // content
    window.scrollTo(0, 0);
    setIsOpen(open);
  };

  const { x, y, strategy, refs, context } = useFloating({
    whileElementsMounted: autoUpdate,
    open: isOpen,
    onOpenChange: onOpenChange,
    placement: "bottom-start",
    middleware: [flip(), shift(), offset(20)],
  });

  const click = useClick(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([click]);

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <div className="navControl">
      <div className="onlyDesktop">
        <Link to={paths.login}>Log in</Link>
      </div>

      <div>
        <NavControlButton
          ref={refs.setReference}
          onClick={handleClick}
          className="buttonMenu"
          {...getReferenceProps()}
        >
          Menu
        </NavControlButton>
        {isOpen && (
          <NavPanelWrapper
            ref={refs.setFloating}
            position={strategy}
            top={y}
            left={x}
            {...getFloatingProps()}
          >
            <NavPanel onClose={onClose} removeScrollEnabled={true} />
          </NavPanelWrapper>
        )}
      </div>
    </div>
  );
};

export default NavControl;
