import { Link } from "react-router-dom";
import { INavItem } from "types/commonTypes";

interface INavItemProps {
  item: INavItem;
  onClick?: () => void;
}

const NavItem = (props: INavItemProps) => {
  const onClick = () => (props.onClick ? props.onClick() : null);

  // Link the title if there's somewhere to link to.
  const title = props.item.to ? (
    <Link to={props.item.to} onClick={onClick}>
      {props.item.title}
    </Link>
  ) : (
    <>{props.item.title}</>
  );

  return <>{title}</>;
};

export default NavItem;
