import { featuresNavContent } from "helpers/data";
import { paths } from "helpers/paths";
import NavItem from "./NavItem/NavItem";
import { NavPanelColumnHeader } from "./NavPanelColumnHeader/NavPanelColumnHeader";
import { Link } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { RemoveScroll } from "react-remove-scroll";
import { NavControlButton } from "../MainNav/NavControlButton";
import { OnlineMark } from "components/Elements/OnlineMark/OnlineMark";
import MainNavMasthead from "../MainNav/MainNavMasthead";
import { MouseEventHandler, useCallback, useEffect } from "react";
import NavList from "../MainNav/NavList";

interface INavPanelProps {
  onClose: () => void;
  removeScrollEnabled?: boolean;
}

const NavPanel: React.FC<INavPanelProps> = ({
  onClose,
  removeScrollEnabled = false,
}) => {
  const { showNewMessages } = useIntercom();

  const handleResize = useCallback(() => {
    if (window.innerWidth > 768) {
      onClose();
    }
  }, [onClose]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  const handleTalkClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    showNewMessages("");
  };

  const handleLinkClick = useCallback(() => {
    onClose();
  }, [onClose]);

  const navItems = featuresNavContent.map((navSection, index) => (
    <div className="panelNavList" key={index}>
      <NavPanelColumnHeader title={navSection.title} />
      <ul>
        {navSection.options.map((option, index) => (
          <NavItem onClick={handleLinkClick} key={index} item={option} />
        ))}
      </ul>
    </div>
  ));

  return (
    <RemoveScroll enabled={removeScrollEnabled}>
      <section className="navPanel">
        <nav className="mainNav onlyMobile">
          <ul>
            <li className="logo">
              <MainNavMasthead />
            </li>
            <li className="navListContainer">
              <NavList />
              <OnlineMark />
            </li>
            <li className="controls">
              <NavControlButton onClick={onClose}>Close</NavControlButton>
            </li>
          </ul>
        </nav>
        <hr />
        <section className="mobileMainNav">
          <ul>
            <li>
              <Link
                onClick={handleLinkClick}
                to={paths.pricing}
                className="buttonLink"
              >
                Get Started
              </Link>
            </li>
            <li>
              <Link
                onClick={handleLinkClick}
                to={paths.login}
                className="buttonLink"
              >
                Login
              </Link>
            </li>
          </ul>
          <ul>
            <li>
              <h2>SKULabs Pro</h2>
            </li>
            <li>
              <Link onClick={handleLinkClick} to={paths.features}>
                Features
              </Link>
            </li>
            <li>
              <Link onClick={handleLinkClick} to={paths.pricing}>
                Pricing
              </Link>
            </li>
            <li>
              <Link onClick={handleLinkClick} to={paths.partners}>
                Hardware
              </Link>
            </li>
            <li>
              <Link onClick={handleLinkClick} to={paths.partners}>
                Partners
              </Link>
            </li>
            <li>
              <Link onClick={handleLinkClick} to={paths.support}>
                Support
              </Link>
            </li>
            <li>
              <Link onClick={handleLinkClick} to={paths.api}>
                API
              </Link>
            </li>
          </ul>
          <ul>
            <li>
              <h2>SKULabs Inventory</h2>
            </li>
            <li>
              <Link onClick={handleLinkClick} to={paths.inventory}>
                Overview
              </Link>
            </li>
            <li>
              <Link onClick={handleLinkClick} to={paths.inventoryPricing}>
                Pricing
              </Link>
            </li>
            <li>
              <Link onClick={handleLinkClick} to={paths.inventorySupport}>
                Support
              </Link>
            </li>
            <li>
              <h2>SKULabs Company</h2>
            </li>
            <li>
              <Link onClick={handleLinkClick} to={paths.about}>
                About
              </Link>
            </li>
            <li>
              <Link onClick={handleLinkClick} to={paths.careers}>
                Careers
              </Link>
            </li>
            <li>
              <Link onClick={handleLinkClick} to={paths.blog}>
                Blog
              </Link>
            </li>
            <li>
              <Link onClick={handleLinkClick} to={paths.instagram}>
                Instagram
              </Link>
            </li>
            <li>
              <Link onClick={handleLinkClick} to={paths.ai}>
                AI
              </Link>
            </li>
            <li>
              <h2>More SKULabs Pro Features</h2>
            </li>
          </ul>
        </section>
        <div className="lists">{navItems}</div>
      </section>
    </RemoveScroll>
  );
};

export default NavPanel;
