import { ReactNode } from "react"

interface ISkulabsContainerProps {
  children: ReactNode | ReactNode[]
}

export const SkulabsContainer = (props: ISkulabsContainerProps) => {
  return (
    <div className="skulabsContainer">
      <div className="skulabsContainerContent">
        {props.children}
      </div>
    </div>
  )
}
