import { paths } from "helpers/paths";
import { BaseSyntheticEvent } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import ButtonMajor from "../Buttons/ButtonMajor/ButtonMajor";
import LiveDemoForm from "../LiveDemoForm/LiveDemoForm";

const FooterCta = () => {
  const buttonTarget = paths.pricing;
  const navigate = useNavigate();
  const clickButton = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    navigate(buttonTarget);
  };

  const path = useLocation();

  if (path.pathname === "/about" || path.pathname === "/skulabsSignup" || path.pathname === buttonTarget) {
    return (<hr />);
  }

  return (
    <section className="footerCta">
      <header>
        <h1>Take the next step in inventory control and order fulfillment.</h1>
        <h2>Talk to a brand success specialist to learn more.</h2>
      </header>
      <div className="buttonWrapper">
        <hr />

        {/* <ButtonMajor
          title='Get started'
          subtitle='Onboard with a specialist or self serve'
          onClick={clickButton}
        /> */}
        <LiveDemoForm />
        <hr />
      </div>
    </section>
  );
};

export default FooterCta;
