import { forwardRef, MouseEventHandler, Ref } from "react"

interface INavControlButtonProps {
  onClick: MouseEventHandler<HTMLButtonElement>
  referenceProps?: Record<string, unknown>
  className?: string
  children: string
}

export const NavControlButton = forwardRef((props: INavControlButtonProps, ref?: Ref<any>) => {
  return (
    <button 
      ref={ref}
      onClick={props.onClick} 
      className={props.className || '' + ' buttonMenu'}
      {...props.referenceProps}
    >
      {/* Adding a span to fix a safari alignment bug */}
      {/* https://stackoverflow.com/questions/13123444/please-explain-text-align-weirdness-in-mobile-safari */}
      <span>{props.children}</span>
    </button>
  )
})

