import { ReactNode } from "react";
import NavPanelIcon from "../NavPanelIcon/NavPanelIcon";

interface INavPanelColumnHeaderProps {
  icon?: ReactNode;
  title: string;
}

export const NavPanelColumnHeader = (props: INavPanelColumnHeaderProps) => {
  return <p className="navPanelColumnHeader">{props.title}</p>;
};
