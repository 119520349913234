import { useMemo } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as AmazonLogo } from "assets/logos/partners/amazon.svg";
import { ReactComponent as BigcommerceLogo } from "assets/logos/partners/bigcommerce.svg";
import { ReactComponent as EtsyLogo } from "assets/logos/partners/etsy.svg";
import { ReactComponent as Magento } from "assets/logos/partners/magento.svg";
import { ReactComponent as ShopifyLogo } from "assets/logos/partners/shopify.svg";
import AddressList from "components/Elements/AddressList/AddressList";
import { paths } from "helpers/paths";

const Footer = () => {
  const currentYear = useMemo(() => new Date().getFullYear(), []);

  return (
    <footer className="mainFooter">
      <section>
        <h1>Company</h1>
        <ul>
          <li>
            <Link to={paths.about}>About us</Link>
          </li>
          <li>
            <Link to={paths.ai}>AI</Link>
          </li>
          <li>
            <Link to={paths.careers}>Careers</Link>
          </li>
          <li>
            <Link to={paths.instagram} target="_blank" rel="noopener">
              Instagram
            </Link>{" "}
            <sup>🔗</sup>
          </li>
          <li>
            <Link to={paths.blog} target="_blank" rel="noopener">
              Blog
            </Link>{" "}
            <sup>🔗</sup>
          </li>
        </ul>
      </section>

      <section>
        <h1>SKULabs Pro</h1>
        <ul>
          <li>
            <Link to={paths.features}>Features</Link>
          </li>
          <li>
            <Link to={paths.pricing}>Pricing</Link>
          </li>
          <li>
            <Link to={paths.hardware}>Hardware</Link>
          </li>
          <li>
            <Link to={paths.integrations}>Partners</Link>
          </li>
          <li>
            <Link to={paths.support} target="_blank" rel="noopener">
              Support
            </Link>{" "}
            <sup>🔗</sup>
          </li>
          <li>
            <Link to={paths.api} target="_blank" rel="noopener">
              API
            </Link>{" "}
            <sup>🔗</sup>
          </li>
        </ul>
      </section>

      <section>
        <h1>SKULabs Inventory</h1>
        <ul>
          <li>
            <Link to={paths.inventory}>Overview</Link>
          </li>
          <li>
            <Link to={paths.inventoryPricing}>Pricing</Link>
          </li>
          <li>
            <Link to={paths.inventorySupport} target="_blank">
              Support
            </Link>{" "}
            <sup>🔗</sup>
          </li>
        </ul>
      </section>

      <section>
        <h1>Partners</h1>
        <ul>
          <li>
            <span className="icon">
              <ShopifyLogo />
            </span>
            <Link className="shopify" to={paths.shopify}>
              Shopify
            </Link>
          </li>
          <li>
            <span className="icon">
              <AmazonLogo />
            </span>
            <Link className="amazon" to={paths.amazon}>
              Amazon
            </Link>
          </li>
          <li>
            <span className="icon">
              <BigcommerceLogo />
            </span>
            <Link className="bigcommerce" to={paths.bigCommerce}>
              Bigcommerce
            </Link>
          </li>
          <li>
            <span className="icon">
              <EtsyLogo />
            </span>
            <Link className="etsy" to={paths.etsy}>
              Etsy
            </Link>
          </li>
          <li>
            <span className="icon">
              <Magento />
            </span>
            <Link className="magento" to={paths.magento}>
              Magento
            </Link>
          </li>

          <li>
            <span className="icon"></span>
            <Link to={paths.integrations}>and more...</Link>
          </li>
        </ul>
      </section>

      <section>
        <h1>Contact us</h1>
        <AddressList />
      </section>

      <div className="meta">
        <ul>
          <li>© {currentYear} SKULabs LLC. All rights reserved.</li>
          <li>
            <Link to={paths.sitemap}>Sitemap</Link>
          </li>
          <li>
            <Link to={paths.termsOfUse}>Terms of Use</Link>
          </li>
          <li>
            <Link to={paths.privacyPolicy}>Privacy Policy</Link>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
