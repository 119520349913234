import { forwardRef, Ref } from "react";
import { NavLink } from "react-router-dom";

interface INavListItemProps {
  to: string;
  title: string;
  popperProps?: Record<string, unknown>;
}

const NavListItem = forwardRef(
  (props: INavListItemProps, ref?: Ref<HTMLAnchorElement>) => {
    return (
      <NavLink
        className="navLink"
        id={props.to}
        to={props.to}
        ref={ref}
        {...props.popperProps}
      >
        {props.title}
      </NavLink>
    );
  }
);

export default NavListItem;
