import { ReactComponent as SkulabsLogo } from "assets/logos/logo_skulabs.svg";
import { ReactComponent as SkulabsLogoDarkMode } from "assets/logos/logo_skulabs_dark.svg";
import { NavLink, useLocation } from "react-router-dom";

const MainNavMasthead = () => {
  // See <SkulabsDotCom>
  const location = useLocation();

  return (
    <NavLink to={"/"} aria-label="SKULabs Logo">
      {location.pathname === "/ai" ? <SkulabsLogoDarkMode /> : <SkulabsLogo />}
    </NavLink>
  );
};

export default MainNavMasthead;
