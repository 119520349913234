import { OnlineMark } from "components/Elements/OnlineMark/OnlineMark";
import MainNavMasthead from "./MainNavMasthead";
import NavControl from "./NavControl";
import NavList from "./NavList";
import InventoryNavList from "./InventoryNavList";
import { useLocation } from "react-router-dom";

const MainNav = () => {
  const location = useLocation();

  return (
    <nav className="mainNav">
      <ul>
        <li className="logo">
          <MainNavMasthead />
          {/* {
            location.pathname === '/inventory' ? <span className="inventory-logo-subtext">Inventory</span> : <span className="pro-logo-subtext">Pro</span>
          }  */}
        </li>

        <li className="navListContainer">
          {location.pathname === "/inventory" ? (
            <InventoryNavList />
          ) : (
            <NavList />
          )}
          <OnlineMark />
        </li>

        <li className="controls">
          <NavControl />
        </li>
      </ul>
    </nav>
  );
};

export default MainNav;
