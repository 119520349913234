
import ReactDOM from 'react-dom/client';
import SkulabsRouter from 'components/Routing/SkulabsRouter';
import { IntercomProvider } from 'react-use-intercom';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from "react-ga4";
import { getAndStoreUtmParams } from 'helpers/utm';

const INTERCOM_APP_ID = 'wh0ifzq6';
const GA4_ID = 'G-EBRVP1VL0E'; // SKULabs Front - GA4

ReactGA.initialize(GA4_ID);

// Call the function to store UTM parameters
getAndStoreUtmParams();

const root = ReactDOM.createRoot(document.getElementById('skulabs') as HTMLElement);
root.render(
  <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={true}>
    {/* <React.StrictMode> */}
    <BrowserRouter>
      <SkulabsRouter />
    </BrowserRouter>
    {/* </React.StrictMode> */}
  </IntercomProvider>
);
